import React, { Component } from 'react';
import config from 'data/SiteConfig';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';

class TopBanner extends Component {
    populateNav = () => {
        return config.guideIdTab.map((data, index) => 
            <div key={index} 
                 className="bottom__nav" 
                 onClick={() => navigate(`/guide/${config.guideTab[index]}`)}
            >
                 {data}
            </div>
        )
    }

    render() {
        const {image,firstString ,secondString ,subtitle ,description} = this.props;
        return (
            <div id="TopBanner" style={{
                backgroundImage : `url(${image})`
            }}>
                <div className="top">
                    <div className="top__title">
                        <img src={require('assets/img/partial/Placeholder_Title.png')} alt=""/>
                        <div className="content">
                            <p className="first">{firstString}</p>
                            <p className="second">{secondString}</p>
                            <div className="third">
                                <p>{subtitle}</p>
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-mobile">
                    <div className="title">
                        <span>{firstString} </span>
                        <span>{secondString}</span>
                    </div>
                    <hr/>
                    <div className="description">
                        <p>{subtitle}</p>
                        <p>{description}</p>
                    </div>
                </div>
                {/* <div className="bottom">
                    {this.populateNav()}
                </div> */}
            </div>
        );
    }
}

TopBanner.propTypes = {
    image : PropTypes.string,
    firstString : PropTypes.string,
    secondString : PropTypes.string,
    subtitle : PropTypes.string,
    description : PropTypes.string
}

export default TopBanner;