import React, { Component } from 'react';
import {graphql} from 'gatsby';
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import Layout from 'layout';
import TopBanner from 'partials/topBanner';
import ModeTemplate from './modeTemplate';
import BattleRoyale from './battleRoyale';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modeActions from 'redux/actions/mode';

class ModeLayout extends Component {

    componentDidMount(){
        const {pageContext , modeActions} = this.props;
        modeActions.change_tab(pageContext.pages);
    }

    componentWillReceiveProps() {
		if(typeof window !== 'undefined') {
			window.addEventListener('popstate', (event) => {
				window.location.reload();
			});
		}
	}

    handleClick = (data) =>{
        this.props.modeActions.change_tab(data);
		if(typeof window !== 'undefined') {
            window.history.pushState({}, document.title, `${window.location.origin}/guide/mode/${data}`);
        }
    }
    
    generateTab = (pages) =>{
        const { mode } = this.props.data;
        return mode.edges.map((data,x)=>
            <li key={x} className={data.node.slug === pages ? `active`: ``} onClick={()=>this.handleClick(data.node.slug)}>
                {data.node.name}
            </li>
        )
    }

    generateTabContent = (pages) =>{
        const { mode, kelas } = this.props.data;
        return mode.edges.map((data,x)=>
            <div key={x} className={`page animated fadeIn fast ${(data.node.slug === pages ? `active`: ``)}`}>
                <div className="title">
                    <span>{data.node.name}</span>
                </div>
                {(pages !== 'battle_royale') ?
                    <ModeTemplate
                        img={data.node.image}
                        desc={data.node.description}
                    /> 
                    : 
                    <BattleRoyale 
                        img={data.node.image}
                        kelas={kelas}
                        desc={data.node.description}
                        {...this.props}
                    />
                }
            </div>
        )
    }

    render() {
        const {tab} = this.props.mode;
        return (
            <Layout>
                <Helmet title={"Mode - " + config.siteTitle} />
                <div id="ModeLayout" className="content-container">
                    <TopBanner 
                        image={require('assets/img/mode/BG_Mode.jpg')} 
                        firstString="Berbagai"
                        secondString="Mode"
                        subtitle="Tantang kemampuan kamu"
                        description="jadilah yang terbaik"
                    />
                    <div className="content">
                        <div className="battle_royale tabs">
                            <ul className="tabs__title">
                                {this.generateTab(tab)}
                            </ul>
                            <div className="tabs__content">
                                {this.generateTabContent(tab)}
                            </div>
                        </div>    
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { mode: state.mode }
}

function mapDispatchToProps(dispatch) {
    return {
        modeActions: bindActionCreators(modeActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModeLayout);

export const query = graphql`
{
    mode: allDirectusMode {
      edges {
        node {
          name
          description
          slug
          image
        }
      }
    }
    kelas: allDirectusModeKelas {
      edges {
        node {
          name
          icon
          items {
            data {
              name
              description
              image
            }
          }
        }
      }
    }
  }
`;