import React, { Component } from 'react';

class ModeTemplate extends Component {
    render() {
        const {img, desc} = this.props;
        return (
            <div className="detail_tab">
                <div className="img_banner">
                    <img src={img} alt="" className="img-fluid"/>
                </div>
                <div className="info">
                    <p>{desc}</p>
                </div>
            </div>
        );
    }
}

export default ModeTemplate;