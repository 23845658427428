import React, { Component } from 'react';

class BattleRoyale extends Component {

    generateClass = () =>{
        const {modeActions, kelas} = this.props;
        const {open, type} = this.props.mode;
        return kelas.edges.map((data,x)=>
            <li key={x} className={`collapse ${(open && type === (data.node.name.toLowerCase())) ? `open`:``}`}>
                <div className="collapse_title" onClick={()=>modeActions.toggle_collapse(data.node.name.toLowerCase())}>
                    <div className="icon">
                        <img src={data.node.icon} alt="" className="img-fluid"/>
                    </div>
                    <p>{data.node.name}</p>
                </div>
                <div className="collapse_content">
                    <ul className="collapse_content__details">
                        {data.node.items.data.map((datas,y)=>
                            <li key={y}>
                                <div className="img">
                                    <img src={datas.image} alt="" className="img-fluid"/>
                                </div>
                                <div className="text">
                                    <h3>{datas.name}</h3>
                                    <p>{datas.description}</p>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        );
    }

    render() {
        const {img, desc} = this.props;
        return (
            <div id="battle" className="detail_tab">
                <div className="img_banner">
                    <img src={img} alt="" className="img-fluid"/>
                </div>
                <div className="info">
                    <p>{desc}</p>
                </div>
                <div className="class">
                    <div className="title">Class</div>
                    <ul className="class_box">
                        {this.generateClass()}
                    </ul>
                </div>
            </div>
        );
    }
}

export default BattleRoyale;